// PrivateRoute.js
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../components/Allmenus/Fourmenu/AuthProvider";
import { Link } from "react-router-dom";
import Bredcrumb from "../components/Bredcrumb/Main";
import img from "../assets/images/em.jpg";
import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../components/Allmenus/Fourmenu/Deliciousmenu";

function PrivateRoute() {
  const [tabMenu, tabActive] = useState({ a: true });
  const { state } = useAuth();
  const [showData, setShowData] = useState([]);
  const value = "Titanic";
  const [formData, setFormData] = useState({
    showName: "Titanic",
    hotelName: "",
    hotelLocation: "",
    image: "",
    selectWhere: "Crewe",
    showDate: "",
    hotelLink: "",
    createdAt: serverTimestamp(),
  });
  const [updateData, setUpdateData] = useState({
    showName: "Titanic",
    hotelName: "",
    hotelLocation: "",
    image: "",
    selectWhere: "Crewe",
    showDate: "",
    hotelLink: "",
    createdAt: serverTimestamp(),
  });
  const [dataForUpdate, setDataForUpdate] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedVenue, setSelectedVenue] = useState("");
  const [selectedShowDate, setSelectedShowDate] = useState("");
  const [updateSelectShowDate, setUpdateSelectShowDate] = useState("");

  const updateDocument = async () => {
    console.log(updateData.image);
    const collectionRef = collection(db, "venues");
    const queryRef = query(
      collectionRef,
      where("showDate", "==", updateData.showDate)
    );

    try {
      const querySnapshot = await getDocs(queryRef);
      console.log("Maybe here");
      console.log(selectedShowDate);
      console.log(selectedShowDate);
      console.log(updateData);
      if (!querySnapshot.empty) {
        // Loop through the matching documents (in case there are multiple)
        querySnapshot.forEach(async (docToUpdate) => {
          const documentId = docToUpdate.id;
          const documentRef = doc(collectionRef, documentId);

          // Assuming formData is an object containing the updated values
          const updatedData = {
            // Update each field as needed
            showName: updateData.showName,
            hotelName: updateData.hotelName,
            hotelLocation: updateData.hotelLocation,
            selectWhere: updateData.selectWhere,
            showDate: updateData.showDate,
            hotelLink: updateData.hotelLink,
            createdAt: updateData.createdAt,
          };

          // Perform the update
          await updateDoc(documentRef, updatedData);

          console.log("Document updated successfully");
          alert("Document updated successfully");
        });
      } else {
        console.log("No matching documents found");
        alert("Error: No matching documents found");
      }
    } catch (error) {
      console.error("Error updating document:", error);
      alert("Error updating document: " + error.message);
    }
  };

  const deleteDocument = async () => {
    console.log("is it here?");
    const collectionRef = collection(db, "venues");
    const queryRef = query(
      collectionRef,
      where("hotelName", "==", selectedVenue),
      where("showDate", "==", selectedShowDate)
    );

    try {
      const querySnapshot = await getDocs(queryRef);
      console.log("Maybe here");
      console.log(selectedShowDate);
      console.log(selectedVenue);

      if (!querySnapshot.empty) {
        // Assuming there's at most one matching document
        const docToDelete = querySnapshot.docs[0];
        const documentId = docToDelete.id;

        const documentRef = doc(collectionRef, documentId);
        await deleteDoc(documentRef);

        console.log("Document deleted successfully");
        alert("Document deleted successfully");
      } else {
        console.log("No matching documents found");
        alert("Error: No matching documents found");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      alert("Error deleting document: " + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      const docRef = await addDoc(collection(db, "venues"), {
        // Replace "your_collection_name" with the actual name of your collection
        showName: formData.showName,
        hotelName: formData.hotelName,
        hotelLocation: formData.hotelLocation,
        selectWhere: formData.selectWhere,
        showDate: formData.showDate,
        hotelLink: formData.hotelLink,
        createdAt: formData.createdAt,
      });

      console.log("Document written with ID: ", docRef.id);
      window.alert("Successfully submitted");

      // Clear the form fields or reset the formData state
      setFormData({ name: "", email: "", message: "" });
      setInputValue("");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };
  const handleDeleteChange = (event) => {
    // Assuming that event.target.name corresponds to the state variable you want to update
    // If not, you may need to adjust the state variables and the name attribute in your select elements
    const { name, value } = event.target;

    if (name === "venue") {
      setSelectedVenue(value);
    } else if (name === "showDate") {
      setSelectedShowDate(value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleUpdateChange = (e) => {
    const { name, value } = e.target;

    setUpdateData({
      ...updateData,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchData(value).then((data) => {
      setShowData(data);

      console.log(data[0].selectWhere);
      console.log(data[1].selectWhere);
    });
  }, [value]);

  useEffect(() => {
    fetchShowData(value).then((data) => {
      setDataForUpdate(data);
      setSelectedVenue(data[0].hotelName);
      setSelectedShowDate(data[0].showDate);

      console.log(selectedVenue);
      console.log(selectedShowDate);
      console.log(dataForUpdate);
    });
  }, [value]);

  console.log("Checking authentication status :", state.isAuthenticated);
  if (!state.isAuthenticated) {
    // If the user is not authenticated, you can return null or a message
    console.log("not authenticated");
    return null;
  }

  const fetchData = async (value) => {
    const q = query(collection(db, "requests"));

    try {
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(data);
      return data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchShowData = async (value) => {
    const q = query(collection(db, "venues"));

    try {
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return (
    <div>
      {state.isAuthenticated && <Outlet />}
      <Bredcrumb title="heheheheh" img={img} subtitle="Hi Emily :)" />
      <section className="team-section">
        <div className="auto-container">
          <section className="menu-section">
            <div className="auto-container">
              <div className="title-box centered">
                <div className="subtitle">
                  <span>Immersive theatre and dining experience</span>
                </div>

                <div className="pattern-image"></div>

                <h2>Information</h2>
              </div>
              <button
                onClick={() => {
                  console.log(updateData);
                  console.log(selectedShowDate);
                }}
              >
                debug
              </button>
              <div className="tabs-box menu-tabs">
                <div className="buttons">
                  <ul className="tab-buttons clearfix">
                    <li
                      className={`tab-btn ${tabMenu.a && "active-btn"}`}
                      onClick={() => tabActive({ a: true })}
                      data-tab="#tab-1"
                    >
                      <span>Delete a show</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.b && "active-btn"}`}
                      onClick={() => tabActive({ b: true })}
                      data-tab="#tab-2"
                    >
                      <span>Add New Show</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.c && "active-btn"}`}
                      onClick={() => tabActive({ c: true })}
                      data-tab="#tab-3"
                    >
                      <span>Update</span>
                    </li>
                  </ul>
                </div>
                <div className="tabs-content">
                  <div
                    className={`tab ${tabMenu.a && "active-tab"}`}
                    id="tab-1"
                  >
                    <section className="contact-page">
                      <div className="auto-container">
                        <div className="c-page-form-box">
                          <div className="row clearfix">
                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                              <div className="title-box centered">
                                <h2>Delete a show</h2>
                                <div className="text desc">
                                  Select the name of the hotel, and the date of
                                  the event
                                </div>
                              </div>
                              <div className="default-form reservation-form">
                                <form
                                  method="post"
                                  target="_self"
                                  encType="multipart/form-data"
                                  acceptCharset="UTF-8"
                                >
                                  <div className="clearfix">
                                    <div className="form-group">
                                      <div className="field-inner">
                                        Please select a show
                                        <div className="field-inner">
                                          <select
                                            className="l-icon"
                                            name="venue"
                                            value={selectedVenue}
                                            onChange={handleDeleteChange}
                                            id="selectShowF"
                                          >
                                            {dataForUpdate.map((item) => (
                                              <option
                                                key={item.id}
                                                value={item.hotelName}
                                              >
                                                {item.hotelName}
                                              </option>
                                            ))}
                                          </select>
                                          <span className="arrow-icon far fa-angle-down"></span>
                                        </div>
                                        <div className="form-group col-lg-4 col-md-6 col-sm-12"></div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="field-inner">
                                        <select
                                          className="l-icon"
                                          name="showDate"
                                          value={selectedShowDate}
                                          onChange={handleDeleteChange}
                                        >
                                          {dataForUpdate.map((item) => (
                                            <option
                                              key={item.id}
                                              value={item.showDate}
                                            >
                                              {item.showDate}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <input
                                        type="hidden"
                                        name="g-recaptcha-response"
                                        id="g-recaptcha-response"
                                        value=""
                                        autocomplete="off"
                                      />
                                      <button
                                        type="button"
                                        className="theme-btn btn-style-one clearfix"
                                        onClick={deleteDocument}
                                      >
                                        <span className="btn-wrap">
                                          <span className="text-one">
                                            Delete
                                          </span>
                                          <span className="text-two">
                                            Delete
                                          </span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                              <img
                                src={img}
                                style={{ backgroundSize: "cover" }}
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div
                    className={`tab ${tabMenu.b && "active-tab"}`}
                    id="tab-2"
                  >
                    <section className="contact-page">
                      <div className="auto-container">
                        <div className="c-page-form-box">
                          <div className="row clearfix">
                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                              <div className="title-box centered">
                                <h2>Add a new show</h2>
                                <div className="text desc">
                                  This page is strictly for Emily Chatfield
                                  Lewis, if your name is not Emily Chatifeld
                                  Lewis I am calling the police
                                </div>
                              </div>
                              <div className="default-form reservation-form">
                                <form
                                  method="post"
                                  target="_self"
                                  encType="multipart/form-data"
                                  acceptCharset="UTF-8"
                                >
                                  <div className="clearfix">
                                    <div className="form-group">
                                      <div className="field-inner">
                                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                          <div className="field-inner">
                                            <span className="alt-icon far fa-user"></span>
                                            <select
                                              className="l-icon"
                                              name="showName"
                                              value={formData.showName}
                                              onChange={handleChange}
                                            >
                                              <option value="Titanic">
                                                Titanic
                                              </option>
                                              <option value="JFK">JFK</option>
                                              <option value="Scrooge">
                                                Scrooge
                                              </option>
                                              <option value="TheOverlookHotel">
                                                The Overlook Hotel
                                              </option>
                                            </select>
                                            <span className="arrow-icon far fa-angle-down"></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="field-inner">
                                        <input
                                          type="text"
                                          name="hotelName"
                                          placeholder="Hotel name"
                                          required
                                          value={formData.hotelName}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="field-inner">
                                        <input
                                          type="text"
                                          name="hotelLink"
                                          placeholder="Hotel Link"
                                          required
                                          value={formData.hotelLink}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="field-inner">
                                        <input
                                          type="text"
                                          name="showDate"
                                          placeholder="Show date example(26TH OF JANUARY 2024)"
                                          required
                                          value={formData.showDate}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="field-inner">
                                        <input
                                          type="text"
                                          name="hotelLocation"
                                          placeholder="Hotel location"
                                          required
                                          value={formData.hotelLocation}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                      <div className="field-inner">
                                        <span className="alt-icon far fa-user"></span>
                                        <select
                                          className="l-icon"
                                          name="selectWhere"
                                          value={formData.selectWhere}
                                          onChange={handleChange}
                                        >
                                          <option value="Bath">Bath</option>
                                          <option value="Birmingham">
                                            Birmingham
                                          </option>
                                          <option value="Blackpool">
                                            Blackpool
                                          </option>
                                          <option value="Bournemouth">
                                            Bournemouth
                                          </option>
                                          <option value="Brighton">
                                            Brighton
                                          </option>
                                          <option value="Bristol">
                                            Bristol
                                          </option>
                                          <option value="Cambridge">
                                            Bristol
                                          </option>
                                          <option value="Cardiff">
                                            Cardiff
                                          </option>
                                          <option value="Cheltnam">
                                            Cheltnam
                                          </option>
                                          <option value="Coventry">
                                            Coventry
                                          </option>
                                          <option value="Dundee">Dundee</option>
                                          <option value="Edinburgh">
                                            Edinburgh
                                          </option>
                                          <option value="Exeter">Exeter</option>
                                          <option value="Hull">Hull</option>
                                          <option value="Ipswich">
                                            Ipswich
                                          </option>
                                          <option value="Leeds">Leeds</option>
                                          <option value="Leicester">
                                            Leicester
                                          </option>
                                          <option value="Liverpool">
                                            Liverpool
                                          </option>
                                          <option value="London">London</option>
                                          <option value="Manchester">
                                            Manchester
                                          </option>
                                          <option value="Middlesbrough">
                                            Middlesbrough
                                          </option>
                                          <option value="Milton Keynes">
                                            Milton Keynes
                                          </option>
                                          <option value="Newcastle">
                                            Newcastle
                                          </option>
                                          <option value="Northampton">
                                            Northampton
                                          </option>
                                          <option value="Norwich">
                                            Norwich
                                          </option>
                                          <option value="Oxford">Oxford</option>
                                          <option value="Peterborough">
                                            Peterborough
                                          </option>
                                          <option value="plymouth">
                                            plymouth
                                          </option>
                                          <option value="Reading">
                                            Reading
                                          </option>
                                          <option value="Sheffield">
                                            Sheffield
                                          </option>
                                          <option value="Shrewsbury">
                                            Shrewsbury
                                          </option>
                                          <option value="Southampton">
                                            Southampton
                                          </option>
                                          <option value="Stoke-on-Trent">
                                            Stoke-on-Trent
                                          </option>
                                          <option value="Sunderland">
                                            Sunderland
                                          </option>
                                          <option value="Swansea">
                                            Swansea
                                          </option>
                                          <option value="Swindon">
                                            Swindon
                                          </option>
                                          <option value="TunbridgeWells">
                                            TunbridgeWells
                                          </option>
                                          <option value="WestWales">
                                            WestWales
                                          </option>
                                          <option value="Weston-Super-Mare">
                                            Weston-super-Mare
                                          </option>
                                          <option value="Wrexham">
                                            Wrexham
                                          </option>

                                          <option value="Abderdeen">
                                            Aberdeen
                                          </option>
                                          <option value="Burnley">
                                            Burnley
                                          </option>
                                          <option value="Cambridge">
                                            Cambridge
                                          </option>
                                          <option value="Carlisle">
                                            Carlisle
                                          </option>
                                          <option value="Cornwall">
                                            Cornwall
                                          </option>
                                          <option value="Derby">Derby</option>
                                          <option value="Devon">Devon</option>
                                          <option value="Glasgow">
                                            Glasgow
                                          </option>
                                          <option value="IsleOfMan">
                                            Isle Of Man
                                          </option>
                                          <option value="Lincoln">
                                            Lincoln
                                          </option>
                                          <option value="MidWales">
                                            Mid Wales
                                          </option>
                                          <option value="Nottingham">
                                            Nottingham
                                          </option>
                                          <option value="Portsmouth">
                                            Portsmouth
                                          </option>
                                          <option value="Stafford">
                                            Stafford
                                          </option>
                                          <option value="Worcester">
                                            Worcester
                                          </option>
                                          <option value="York">York</option>
                                        </select>
                                        <span className="arrow-icon far fa-angle-down"></span>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <input
                                        type="hidden"
                                        name="g-recaptcha-response"
                                        id="g-recaptcha-response"
                                        value=""
                                        autocomplete="off"
                                      />
                                      <button
                                        type="submit"
                                        className="theme-btn btn-style-one clearfix"
                                        onClick={handleSubmit}
                                      >
                                        <span className="btn-wrap">
                                          <span className="text-one">
                                            Add the show
                                          </span>
                                          <span className="text-two">Add</span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12"></div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div
                    className={`tab ${tabMenu.c && "active-tab"}`}
                    id="tab-3"
                  >
                    <section className="contact-page">
                      <div className="auto-container">
                        <div className="c-page-form-box">
                          <div className="row clearfix">
                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                              <div className="title-box centered">
                                <h2>Update a show</h2>
                                <div className="text desc">
                                  This will specifically update from the DATE
                                  that is selected
                                </div>
                              </div>
                              <div className="default-form reservation-form">
                                <form
                                  method="post"
                                  target="_self"
                                  encType="multipart/form-data"
                                  acceptCharset="UTF-8"
                                >
                                  <div className="clearfix">
                                    <div className="form-group">
                                      <div className="field-inner">
                                        <select
                                          className="l-icon"
                                          name="showDate"
                                          value={updateData.showDate}
                                          onChange={handleUpdateChange}
                                        >
                                          {dataForUpdate.map((item) => (
                                            <option
                                              key={item.id}
                                              value={item.showDate}
                                            >
                                              {item.showDate}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="field-inner">
                                        <input
                                          type="text"
                                          name="hotelName"
                                          placeholder="Hotel name"
                                          required
                                          value={updateData.hotelName}
                                          onChange={handleUpdateChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="field-inner">
                                        <input
                                          type="text"
                                          name="hotelLink"
                                          placeholder="Hotel Link"
                                          required
                                          value={updateData.hotelLink}
                                          onChange={handleUpdateChange}
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <div className="field-inner">
                                        <input
                                          type="text"
                                          name="hotelLocation"
                                          placeholder="Hotel location"
                                          required
                                          value={updateData.hotelLocation}
                                          onChange={handleUpdateChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="field-inner">
                                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                          <div className="field-inner">
                                            <span className="alt-icon far fa-user"></span>
                                            <select
                                              className="l-icon"
                                              name="showName"
                                              value={updateData.showName}
                                              onChange={handleUpdateChange}
                                            >
                                              <option value="Titanic">
                                                Titanic
                                              </option>
                                              <option value="JFK">JFK</option>
                                              <option value="TheOverlookHotel">
                                                The Overlook Hotel
                                              </option>
                                              <option value="Scrooge">
                                                Scrooge
                                              </option>
                                            </select>
                                            <span className="arrow-icon far fa-angle-down"></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                      <div className="field-inner">
                                        <span className="alt-icon far fa-user"></span>
                                        <select
                                          className="l-icon"
                                          name="selectWhere"
                                          value={updateData.selectWhere}
                                          onChange={handleUpdateChange}
                                        >
                                          <option value="Bath">Bath</option>
                                          <option value="Birmingham">
                                            Birmingham
                                          </option>
                                          <option value="Blackpool">
                                            Blackpool
                                          </option>
                                          <option value="Bournemouth">
                                            Bournemouth
                                          </option>
                                          <option value="Brighton">
                                            Brighton
                                          </option>
                                          <option value="Bristol">
                                            Bristol
                                          </option>
                                          <option value="Cambridge">
                                            Bristol
                                          </option>
                                          <option value="Cardiff">
                                            Cardiff
                                          </option>
                                          <option value="Cheltnam">
                                            Cheltnam
                                          </option>
                                          <option value="Coventry">
                                            Coventry
                                          </option>
                                          <option value="Dundee">Dundee</option>
                                          <option value="Edinburgh">
                                            Edinburgh
                                          </option>
                                          <option value="Exeter">Exeter</option>
                                          <option value="Hull">Hull</option>
                                          <option value="Ipswich">
                                            Ipswich
                                          </option>
                                          <option value="Leeds">Leeds</option>
                                          <option value="Leicester">
                                            Leicester
                                          </option>
                                          <option value="Liverpool">
                                            Liverpool
                                          </option>
                                          <option value="London">London</option>
                                          <option value="Manchester">
                                            Manchester
                                          </option>
                                          <option value="Middlesbrough">
                                            Middlesbrough
                                          </option>
                                          <option value="Milton Keynes">
                                            Milton Keynes
                                          </option>
                                          <option value="Newcastle">
                                            Newcastle
                                          </option>
                                          <option value="Northampton">
                                            Northampton
                                          </option>
                                          <option value="Norwich">
                                            Norwich
                                          </option>
                                          <option value="Oxford">Oxford</option>
                                          <option value="Peterborough">
                                            Peterborough
                                          </option>
                                          <option value="plymouth">
                                            plymouth
                                          </option>
                                          <option value="Reading">
                                            Reading
                                          </option>
                                          <option value="Sheffield">
                                            Sheffield
                                          </option>
                                          <option value="Shrewsbury">
                                            Shrewsbury
                                          </option>
                                          <option value="Southampton">
                                            Southampton
                                          </option>
                                          <option value="Stoke-on-Trent">
                                            Stoke-on-Trent
                                          </option>
                                          <option value="Sunderland">
                                            Sunderland
                                          </option>
                                          <option value="Swansea">
                                            Swansea
                                          </option>
                                          <option value="Swindon">
                                            Swindon
                                          </option>
                                          <option value="TunbridgeWells">
                                            TunbridgeWells
                                          </option>
                                          <option value="WestWales">
                                            WestWales
                                          </option>
                                          <option value="Weston-Super-Mare">
                                            Weston-super-Mare
                                          </option>
                                          <option value="Wrexham">
                                            Wrexham
                                          </option>
                                          <option value="York">York</option>
                                          <option value={"Sold Out"}>
                                            Sold Out
                                          </option>
                                        </select>
                                        <span className="arrow-icon far fa-angle-down"></span>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <input
                                        type="hidden"
                                        name="g-recaptcha-response"
                                        id="g-recaptcha-response"
                                        value=""
                                        autocomplete="off"
                                      />
                                      <button
                                        type="button"
                                        className="theme-btn btn-style-one clearfix"
                                        onClick={updateDocument}
                                      >
                                        <span className="btn-wrap">
                                          <span className="text-one">
                                            Update
                                          </span>
                                          <span className="text-two">Add</span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                              <img
                                src={img}
                                style={{ backgroundSize: "cover" }}
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      {showData.map((item) => (
        <div className="team-block col-lg-4 col-md-6 col-sm-12">
          <div
            className="inner-box wow fadeInUp"
            data-wow-duration="1500ms"
            data-wow-delay="0ms"
          >
            <div className="image"></div>
            <h3>
              <Link to={item.hotelLink}>{item.show}</Link>
            </h3>
            <div className="designation">{item.venueName}</div>
            <div className="text">{item.venueLink}</div>
            <div className="text">{item.venueNumber}</div>
            <div className="text">{item.venueEmail}</div>
            <div className="text">{item.venueLocation}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PrivateRoute;
