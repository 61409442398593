import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../Fourmenu/Deliciousmenu";
import { useEffect, useState } from "react";
import manSmoking from "../../../assets/images/resource/JFK_POSTER_NO_TEXT.jpg";
import menFashion from "../../../assets/images/60fashion.jpg";
import womenFashion from "../../../assets/images/60women.jpg";
import warning from "../../../assets/images/warning3.png";
import teamthree from "../../../assets/images/resource/jfk coming soon.jpg";
import { Link } from "react-router-dom";

import BathImg from "../../../assets/images/resource/Website/UK maps/jfk/Bath jfk.jpg";
import BirminghamImg from "../../../assets/images/resource/Website/UK maps/jfk/Birmingham jfk.jpg";
import BlackpoolImg from "../../../assets/images/resource/Website/UK maps/jfk/Blackpool jfk.jpg";
import BournemouthImg from "../../../assets/images/resource/Website/UK maps/jfk/Bournemouth jfk.jpg";
import BrightonImg from "../../../assets/images/resource/Website/UK maps/jfk/Brighton jfk.jpg";
import BristolImg from "../../../assets/images/resource/Website/UK maps/jfk/Bristol jfk.jpg";
import CambridgeImg from "../../../assets/images/resource/Website/UK maps/jfk/Cambridge jfk.jpg";
import CardiffImg from "../../../assets/images/resource/Website/UK maps/jfk/Cardiff jfk.jpg";
import CheltenhamImg from "../../../assets/images/resource/Website/UK maps/jfk/Cheltenham jfk.jpg";
import CoventryImg from "../../../assets/images/resource/Website/UK maps/jfk/Coventry jfk.jpg";
import DundeeImg from "../../../assets/images/resource/Website/UK maps/jfk/Dundee jfk.jpg";
import EdinburghImg from "../../../assets/images/resource/Website/UK maps/jfk/Edinburgh jfk.jpg";
import ExeterImg from "../../../assets/images/resource/Website/UK maps/jfk/Exeter jfk.jpg";
import HullImg from "../../../assets/images/resource/Website/UK maps/jfk/Hull jfk.jpg";
import IpswichImg from "../../../assets/images/resource/Website/UK maps/jfk/Ipswich jfk.jpg";
import LeedsImg from "../../../assets/images/resource/Website/UK maps/jfk/Leeds jfk.jpg";
import LeicesterImg from "../../../assets/images/resource/Website/UK maps/jfk/Leicester jfk.jpg";
import LiverpoolImg from "../../../assets/images/resource/Website/UK maps/jfk/Liverpool jfk.jpg";
import LondonImg from "../../../assets/images/resource/Website/UK maps/jfk/London jfk.jpg";
import ManchesterImg from "../../../assets/images/resource/Website/UK maps/jfk/Manchester jfk.jpg";
import MiddlesbroughImg from "../../../assets/images/resource/Website/UK maps/jfk/Middlesbrough jfk.jpg";
import MiltonKeynesImg from "../../../assets/images/resource/Website/UK maps/jfk/Milton Keynes jfk.jpg";
import NewcastleImg from "../../../assets/images/resource/Website/UK maps/jfk/Newcastle upon Tyne jfk.jpg";
import NorthamptonImg from "../../../assets/images/resource/Website/UK maps/jfk/Northampton jfk.jpg";
import NorwichImg from "../../../assets/images/resource/Website/UK maps/jfk/Norwich jfk.jpg";
import OxfordImg from "../../../assets/images/resource/Website/UK maps/jfk/Oxford jfk.jpg";
import PeterboroughImg from "../../../assets/images/resource/Website/UK maps/jfk/Peterborough jfk.jpg";
import PlymouthImg from "../../../assets/images/resource/Website/UK maps/jfk/Plymouth jfk.jpg";
import ReadingImg from "../../../assets/images/resource/Website/UK maps/jfk/Reading jfk.jpg";
import SheffieldImg from "../../../assets/images/resource/Website/UK maps/jfk/Sheffield jfk.jpg";
import ShrewsburyImg from "../../../assets/images/resource/Website/UK maps/jfk/Shrewsbury jfk.jpg";
import SouthamptonImg from "../../../assets/images/resource/Website/UK maps/jfk/Southampton jfk.jpg";
import StokeOnTrentImg from "../../../assets/images/resource/Website/UK maps/jfk/Stoke-on-Trent jfk.jpg";
import SunderlandImg from "../../../assets/images/resource/Website/UK maps/jfk/Sunderland jfk.jpg";
import SwanseaImg from "../../../assets/images/resource/Website/UK maps/jfk/Swansea jfk.jpg"; // Replace with the actual path
import SwindonImg from "../../../assets/images/resource/Website/UK maps/jfk/Swindon jfk.jpg";
import TunbridgeWellsImg from "../../../assets/images/resource/Website/UK maps/jfk/Tunbridge Wells jfk.jpg";
import WestWalesImg from "../../../assets/images/resource/Website/UK maps/jfk/West Wales jfk.jpg";
import WestonSuperMareImg from "../../../assets/images/resource/Website/UK maps/jfk/Weston-Super-Mare jfk.jpg";
import WrexhamImg from "../../../assets/images/resource/Website/UK maps/jfk/Wrexham jfk.jpg";
import YorkImg from "../../../assets/images/resource/Website/UK maps/jfk/York jfk.jpg";

//new ones
import LincolnImg from "../../../assets/images/resource/Website/UK maps/jfk/Lincoln.jpg";
import NottinghamImg from "../../../assets/images/resource/Website/UK maps/jfk/Nottingham.jpg";
import IsleOfManImg from "../../../assets/images/resource/Website/UK maps/jfk/Isle of Man.jpg";
import BurnleyImg from "../../../assets/images/resource/Website/UK maps/jfk/Burnley.jpg";
import StaffordImg from "../../../assets/images/resource/Website/UK maps/jfk/Stafford.jpg";
import CornwallImg from "../../../assets/images/resource/Website/UK maps/jfk/Cornwall.jpg";
import DevonImg from "../../../assets/images/resource/Website/UK maps/jfk/Devon.jpg";
import WorcesterImg from "../../../assets/images/resource/Website/UK maps/jfk/Worcester.jpg";
import DerbyImg from "../../../assets/images/resource/Website/UK maps/jfk/Derby.jpg";
import CarlisleImg from "../../../assets/images/resource/Website/UK maps/jfk/Carlisle.jpg";
import AberdeenImg from "../../../assets/images/resource/Website/UK maps/jfk/Aberdeen.jpg";
import GlasgowImg from "../../../assets/images/resource/Website/UK maps/jfk/Glasgow.jpg";
import MidWalesImg from "../../../assets/images/resource/Website/UK maps/jfk/Mid Wales.jpg";
import PortsmouthImg from "../../../assets/images/resource/Website/UK maps/jfk/Portsmouth.jpg";

function Specialmenu() {
  const [tabMenu, tabActive] = useState({ a: true });

  const [showData, setShowData] = useState([]);
  const value = "JFK";
  const [imageSource, setImageSource] = useState("");
  const boxStyles = {
    padding: "0px",
  };

  const findImgPath = (selectWhere) => {
    switch (selectWhere) {
      case "Bath":
        return BathImg;
      case "Birmingham":
        return BirminghamImg;
      case "Blackpool":
        return BlackpoolImg;
      case "Bournemouth":
        return BournemouthImg;
      case "Brighton":
        return BrightonImg;
      case "Bristol":
        return BristolImg;
      case "Cambridge":
        return CambridgeImg;
      case "Cardiff":
        return CardiffImg;
      case "Cheltenham":
        return CheltenhamImg;
      case "Coventry":
        return CoventryImg;
      case "Dundee":
        return DundeeImg;
      case "Edinburgh":
        return EdinburghImg;
      case "Exeter":
        return ExeterImg;
      case "Hull":
        return HullImg;
      case "Ipswich":
        return IpswichImg;
      case "Leeds":
        return LeedsImg;
      case "Leicester":
        return LeicesterImg;
      case "Liverpool":
        return LiverpoolImg;
      case "London":
        return LondonImg;
      case "Manchester":
        return ManchesterImg;
      case "Middlesbrough":
        return MiddlesbroughImg;
      case "Milton Keynes":
        return MiltonKeynesImg;
      case "Newcastle":
        return NewcastleImg;
      case "Northampton":
        return NorthamptonImg;
      case "Norwich":
        return NorwichImg;
      case "Oxford":
        return OxfordImg;
      case "Peterborough":
        return PeterboroughImg;
      case "Plymouth":
        return PlymouthImg;
      case "Reading":
        return ReadingImg;
      case "Sheffield":
        return SheffieldImg;
      case "Shrewsbury":
        return ShrewsburyImg;
      case "Southampton":
        return SouthamptonImg;
      case "Stoke-on-Trent":
        return StokeOnTrentImg;
      case "Sunderland":
        return SunderlandImg;
      case "Swansea":
        return SwanseaImg;
      case "Swindon":
        return SwindonImg;
      case "TunbridgeWells":
        return TunbridgeWellsImg;
      case "WestWales":
        return WestWalesImg;
      case "Weston-super-Mare":
        return WestonSuperMareImg;
      case "Wrexham":
        return WrexhamImg;
      case "York":
        return YorkImg;
      case "Aberdeen":
        return AberdeenImg;
      case "Burnley":
        return BurnleyImg;
      case "Carlisle":
        return CarlisleImg;
      case "Cornwall":
        return CornwallImg;
      case "Derby":
        return DerbyImg;
      case "Devon":
        return DevonImg;
      case "Glasgow":
        return GlasgowImg;
      case "IsleOfMan":
        return IsleOfManImg;
      case "Lincoln":
        return LincolnImg;
      case "MidWales":
        return MidWalesImg;
      case "Nottingham":
        return NottinghamImg;
      case "Portsmouth":
        return PortsmouthImg;
      case "Stafford":
        return StaffordImg;
      case "Worcester":
        return WorcesterImg;
      default:
        return teamthree;
    }
  };

  const fetchData = async (value) => {
    const q = query(
      collection(db, "venues"),
      where("showName", "==", value),
      orderBy("showDate", "asc")
    );

    try {
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(data);
      return data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(value).then((data) => {
      setShowData(data);

      console.log(data[0].selectWhere);
      console.log(data[1].selectWhere);
    });
  }, [value]);

  return (
    <>
      <section className="team-section mb-0">
        <div className="auto-container">
          <section className="menu-section">
            <div className="auto-container">
              <div className="title-box centered">
                <div className="subtitle">
                  <span>
                    3 course meal, 2 hour team game, 1 chance to save the
                    president...
                  </span>
                </div>

                <div className="pattern-image">
                  <img
                    src={
                      require("../../../assets/images/icons/separator.svg")
                        .default
                    }
                    alt="mySvgImage"
                  />
                </div>

                <h2>Information</h2>
              </div>

              <div className="tabs-box menu-tabs">
                <div className="buttons">
                  <ul className="tab-buttons clearfix">
                    <li
                      className={`tab-btn ${tabMenu.a && "active-btn"}`}
                      onClick={() => tabActive({ a: true })}
                      data-tab="#tab-1"
                    >
                      <span>THE EVENT</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.b && "active-btn"}`}
                      onClick={() => tabActive({ b: true })}
                      data-tab="#tab-2"
                    >
                      <span>DRESS CODE</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.c && "active-btn"}`}
                      onClick={() => tabActive({ c: true })}
                      data-tab="#tab-3"
                    >
                      <span>WARNINGS</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.d && "active-btn"}`}
                      onClick={() => tabActive({ d: true })}
                      data-tab="#tab-4"
                    >
                      <span>MENU</span>
                    </li>
                  </ul>
                </div>
                <div className="tabs-content">
                  <div
                    className={`tab ${tabMenu.a && "active-tab"}`}
                    id="tab-1"
                  >
                    <div className="row clearfix">
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div>
                            Our American Themed Dining Experience gives guests a
                            chance to dress up and become a small part of
                            history. The guests will arrive and be greeted by
                            Texan Officials who will point them to the direction
                            of their tables which will be named after an
                            American Agency. It's the 22nd of November 1963 and
                            the guests will be greeted like they are there to
                            help with the motorcade through Texas. The
                            performers engage with the guests as if it is 1963.
                            The room is given 2 hours to complete the task,
                            which is counted down on a projector screen, and a
                            prize is given to the top agency (the agency that
                            finishes first). The evening is split into three
                            parts. Part 1 is designed to get people up on their
                            feet and interact with one another. Part 2 sees
                            agents trying to figure out evidence retrieved from
                            Jacqueline Kennedy’s handbag. Part 3 is the climax
                            of mission. A coding breaking, puzzle piecing team
                            work effort with just ten minutes left before the
                            motorcade begins. An original soundtrack of 60's
                            style music is played throughout the event.
                          </div>
                        </div>
                      </div>
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner mr-5">
                          <img
                            src={manSmoking}
                            className="rounded mx-5"
                            style={{ height: "300px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab ${tabMenu.b && "active-tab"}`}
                    id="tab-2"
                  >
                    <div className="row clearfix">
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <h4 className="mb-3">1960s fashion</h4>
                          <p>
                            We recommend all participants embrace the 1960’s
                            style! Smart-casual clothing is also suitable
                          </p>
                          <p>
                            *Please note that the dress code is a
                            recommendation, not a requirement.*
                          </p>
                        </div>
                      </div>
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <img
                            src={menFashion}
                            style={{ height: "300px" }}
                            className="rounded mr-3"
                          />
                          <img
                            src={womenFashion}
                            style={{ height: "300px", width: "250px" }}
                            className="rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab ${tabMenu.c && "active-tab"}`}
                    id="tab-3"
                  >
                    <div className="row clearfix">
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="mt-4">
                            *Please note that the ending of this show has very
                            graphic scenes filmed of the President’s
                            assassination.
                          </div>
                          <div className="my-3">
                            **This event is 15+, and be accompanied by an adult.
                            Please also note that the price and the menu option
                            will remain the same and the some content of the
                            show may be upsetting.**
                          </div>
                        </div>
                      </div>
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <img
                            src={warning}
                            style={{ height: "250px", width: "250px" }}
                            className="ml-5"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab ${tabMenu.d && "active-tab"}`}
                    id="tab-4"
                  >
                    <div className="row clearfix">
                      <div className="container text-center mb-5">
                        <h4>
                          This is a sample menu and can vary depending on the
                          venue, please contact them directly.
                        </h4>
                      </div>
                      <div className="container text-center">
                        <h2>Starters</h2>
                      </div>
                      <div className="menu-col cols-lg-6 col-md-12 col-sm-12">
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6 " id="boxHoverMenu">
                              <h4 class="card-title my-2  d-flex justify-content-center">
                                Vegetarian
                              </h4>
                              <div className="dish-block">
                                <div className="inner-box" style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-4">
                                        Breakfast tartlet (Vegan substitutions)
                                        <span className="s-info">VEGAN</span>
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    Smoked bacon and egg tartlet - served with
                                    maple glazed smoked bacon crumb, mixed leaf
                                    and tomato salad, and a fresh shot of orange
                                    juice
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 " id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-4">
                                        Breakfast tartlet
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    Smoked bacon and egg tartlet - served with
                                    maple glazed smoked bacon crumb, mixed leaf
                                    and tomato salad, and a fresh shot of orange
                                    juice
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2 className="d-flex justify-content-center mt-4">
                          Mains
                        </h2>
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6 " id="boxHoverMenu">
                              <h4 class="card-title my-2 d-flex justify-content-center">
                                Vegetarian
                              </h4>
                              <div className="dish-block">
                                <div className="title clearfix d-flex justify-content-center">
                                  <div className="ttl clearfix">
                                    <h5 className="ml-3">
                                      'Chicken' Virginia
                                      <span className="s-info">VEGAN</span>
                                    </h5>
                                  </div>
                                  <div className="price"></div>
                                </div>
                                <div className="text desc text-center">
                                  'Chicken breast' in a rich shallot and garlic
                                  cream sauce - served with pureed potatoes,
                                  green beans and parsley Fresh crusty rolls and
                                  butter to side
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 y" id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-3">Chicken Virginia</h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    Chicken breast in a rich shallot and garlic
                                    cream sauce - served with pureed potatoes,
                                    green beans and parsley Fresh crusty rolls
                                    and butter to side
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2 className="d-flex justify-content-center mt-4">
                          Dessert
                        </h2>
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6" id="boxHoverMenu">
                              <h4 class="card-title my-2 d-flex justify-content-center">
                                Vegan
                              </h4>
                              <div className="dish-block">
                                <div className="title clearfix d-flex justify-content-center">
                                  <div className="ttl clearfix text-center">
                                    <h5 className="ml-3">
                                      Classic pumpkin pie
                                      <span className="s-info text-center">
                                        VEGAN
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="price"></div>
                                </div>
                                <div className="text desc text-center">
                                  Classic spiced pumpkin pie served with sweet
                                  whipped cream (v)
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6" id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-3">
                                        Classic pumpkin pie
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    Classic spiced pumpkin pie served with sweet
                                    whipped cream
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="text-center mb-4">
            <span>
              Please note all our bookings are handled externally by our venue
              host
            </span>
          </div>

          <div className="row justify-content-center clearfix">
            {showData.map((item) => (
              <div className="team-block col-lg-4 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="0ms"
                >
                  <div className="image">
                    <img src={findImgPath(item.selectWhere)} alt="" />
                    <div className="overlay-box">CLICK BELOW TO BOOK</div>
                  </div>
                  <h3>
                    <Link to="#">{item.hotelName}</Link>
                  </h3>
                  <div className="designation">{item.showDate}</div>
                  <div className="text">{item.hotelLocation}</div>
                  <Link to={item.hotelLink}>
                    <button
                      type="submit"
                      className="theme-btn btn-style-one clearfix mt-2"
                    >
                      <span className="btn-wrap">
                        <span className="text-one">Book</span>
                        <span className="text-two">Book</span>
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            ))}

            <div className="team-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <div className="image">
                  <img src={teamthree} alt="" />
                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <ul className="social-box"></ul>
                    </div>
                  </div>
                </div>
                <h3>
                  <Link to="#">Coming soon...</Link>
                </h3>
                <div className="designation">2024</div>
                <div className="text">Coming soon...</div>
                <button
                  type="submit"
                  className="theme-btn btn-style-one clearfix mt-3"
                >
                  <span className="btn-wrap">
                    <span className="text-one">Book</span>
                    <span className="text-two">Book</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Specialmenu;
