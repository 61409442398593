import React from "react";
import { Link } from "react-router-dom";
import teamthree from "../../../assets/images/resource/overlook coming soon.jpg";
import { useState } from "react";
import manSmoking from "../../../assets/images/resource/The_Overlook_Poster_NO_TEXT.jpg";
import menFashion from "../../../assets/images/twoOldMen.jpg";
import womenFashion from "../../../assets/images/womenFashion2.jpg";
import warning from "../../../assets/images/warning3.png";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../Fourmenu/Deliciousmenu";
import { useEffect } from "react";

import BathImg from "../../../assets/images/resource/Website/UK maps/Overlook/Bath Overlook.jpg";
import BirminghamImg from "../../../assets/images/resource/Website/UK maps/Overlook/Birmingham Overlook.jpg";
import BlackpoolImg from "../../../assets/images/resource/Website/UK maps/Overlook/Blackpool Overlook.jpg";
import BournemouthImg from "../../../assets/images/resource/Website/UK maps/Overlook/Bournemouth Overlook.jpg";
import BrightonImg from "../../../assets/images/resource/Website/UK maps/Overlook/Brighton Overlook.jpg";
import BristolImg from "../../../assets/images/resource/Website/UK maps/Overlook/Bristol Overlook.jpg";
import CambridgeImg from "../../../assets/images/resource/Website/UK maps/Overlook/Cambridge Overlook.jpg";
import CardiffImg from "../../../assets/images/resource/Website/UK maps/Overlook/Cardiff Overlook.jpg";
import CheltenhamImg from "../../../assets/images/resource/Website/UK maps/Overlook/Cheltenham Overlook.jpg";
import CoventryImg from "../../../assets/images/resource/Website/UK maps/Overlook/Coventry Overlook.jpg";
import DundeeImg from "../../../assets/images/resource/Website/UK maps/Overlook/Dundee Overlook.jpg";
import EdinburghImg from "../../../assets/images/resource/Website/UK maps/Overlook/Edinburgh Overlook.jpg";
import ExeterImg from "../../../assets/images/resource/Website/UK maps/Overlook/Exeter Overlook.jpg";
import HullImg from "../../../assets/images/resource/Website/UK maps/Overlook/Hull Overlook.jpg";
import IpswichImg from "../../../assets/images/resource/Website/UK maps/Overlook/Ipswich Overlook.jpg";
import LeedsImg from "../../../assets/images/resource/Website/UK maps/Overlook/Leeds Overlook.jpg";
import LeicesterImg from "../../../assets/images/resource/Website/UK maps/Overlook/Leicester Overlook.jpg";
import LiverpoolImg from "../../../assets/images/resource/Website/UK maps/Overlook/Liverpool Overlook.jpg";
import LondonImg from "../../../assets/images/resource/Website/UK maps/Overlook/London Overlook.jpg";
import ManchesterImg from "../../../assets/images/resource/Website/UK maps/Overlook/Manchester Overlook.jpg";
import MiddlesbroughImg from "../../../assets/images/resource/Website/UK maps/Overlook/Middlesbrough Overlook.jpg";
import MiltonKeynesImg from "../../../assets/images/resource/Website/UK maps/Overlook/Milton Keynes Overlook.jpg";
import NewcastleImg from "../../../assets/images/resource/Website/UK maps/Overlook/Newcastle upon Tyne Overlook.jpg";
import NorthamptonImg from "../../../assets/images/resource/Website/UK maps/Overlook/Northampton Overlook.jpg";
import NorwichImg from "../../../assets/images/resource/Website/UK maps/Overlook/Norwich Overlook.jpg";
import OxfordImg from "../../../assets/images/resource/Website/UK maps/Overlook/Oxford Overlook.jpg";
import PeterboroughImg from "../../../assets/images/resource/Website/UK maps/Overlook/Peterborough Overlook.jpg";
import PlymouthImg from "../../../assets/images/resource/Website/UK maps/Overlook/Plymouth Overlook.jpg";
import ReadingImg from "../../../assets/images/resource/Website/UK maps/Overlook/Reading Overlook.jpg";
import SheffieldImg from "../../../assets/images/resource/Website/UK maps/Overlook/Sheffield Overlook.jpg";
import ShrewsburyImg from "../../../assets/images/resource/Website/UK maps/Overlook/Shrewsbury Overlook.jpg";
import SouthamptonImg from "../../../assets/images/resource/Website/UK maps/Overlook/Southampton Overlook.jpg";
import StokeOnTrentImg from "../../../assets/images/resource/Website/UK maps/Overlook/Stoke-on-Trent Overlook.jpg";
import SunderlandImg from "../../../assets/images/resource/Website/UK maps/Overlook/Sunderland Overlook.jpg";
import SwanseaImg from "../../../assets/images/resource/Website/UK maps/Overlook/Swansea Overlook.jpg"; // Replace with the actual path
import SwindonImg from "../../../assets/images/resource/Website/UK maps/Overlook/Swindon Overlook.jpg";
import TunbridgeWellsImg from "../../../assets/images/resource/Website/UK maps/Overlook/Tunbridge Wells Overlook.jpg";
import WestWalesImg from "../../../assets/images/resource/Website/UK maps/Overlook/West Wales Overlook.jpg";
import WestonSuperMareImg from "../../../assets/images/resource/Website/UK maps/Overlook/Weston-Super-Mare Overlook.jpg";
import WrexhamImg from "../../../assets/images/resource/Website/UK maps/Overlook/Wrexham Overlook.jpg";
import YorkImg from "../../../assets/images/resource/Website/UK maps/Overlook/York Overlook.jpg";

//new ones
import LincolnImg from "../../../assets/images/resource/Website/UK maps/Overlook/Lincoln.jpg";
import NottinghamImg from "../../../assets/images/resource/Website/UK maps/Overlook/Nottingham.jpg";
import IsleOfManImg from "../../../assets/images/resource/Website/UK maps/Overlook/Isle of Man.jpg";
import BurnleyImg from "../../../assets/images/resource/Website/UK maps/Overlook/Burnley.jpg";
import StaffordImg from "../../../assets/images/resource/Website/UK maps/Overlook/Stafford.jpg";
import CornwallImg from "../../../assets/images/resource/Website/UK maps/Overlook/Cornwall.jpg";
import DevonImg from "../../../assets/images/resource/Website/UK maps/Overlook/Devon.jpg";
import WorcesterImg from "../../../assets/images/resource/Website/UK maps/Overlook/Worcester.jpg";
import DerbyImg from "../../../assets/images/resource/Website/UK maps/Overlook/Derby.jpg";
import CarlisleImg from "../../../assets/images/resource/Website/UK maps/Overlook/Carlisle.jpg";
import AberdeenImg from "../../../assets/images/resource/Website/UK maps/Overlook/Aberdeen.jpg";
import GlasgowImg from "../../../assets/images/resource/Website/UK maps/Overlook/Glasgow.jpg";
import MidWalesImg from "../../../assets/images/resource/Website/UK maps/Overlook/Mid Wales.jpg";
import PortsmouthImg from "../../../assets/images/resource/Website/UK maps/Overlook/Portsmouth.jpg";
import SoldoutImg from "../../../assets/images/resource/Website/UK maps/Overlook/UK MAP Overlook SOLD OUT.jpg";

function Privateevent() {
  const [tabMenu, tabActive] = useState({ a: true });

  const [showData, setShowData] = useState([]);
  const value = "TheOverlookHotel";
  const [imageSource, setImageSource] = useState("");
  const [loading, setLoading] = useState(true);
  const boxStyles = {
    padding: "0px",
  };

  const findImgPath = (selectWhere) => {
    switch (selectWhere) {
      case "Bath":
        return BathImg;
      case "Birmingham":
        return BirminghamImg;
      case "Blackpool":
        return BlackpoolImg;
      case "Bournemouth":
        return BournemouthImg;
      case "Brighton":
        return BrightonImg;
      case "Bristol":
        return BristolImg;
      case "Cambridge":
        return CambridgeImg;
      case "Cardiff":
        return CardiffImg;
      case "Cheltenham":
        return CheltenhamImg;
      case "Coventry":
        return CoventryImg;
      case "Dundee":
        return DundeeImg;
      case "Edinburgh":
        return EdinburghImg;
      case "Exeter":
        return ExeterImg;
      case "Hull":
        return HullImg;
      case "Ipswich":
        return IpswichImg;
      case "Leeds":
        return LeedsImg;
      case "Leicester":
        return LeicesterImg;
      case "Liverpool":
        return LiverpoolImg;
      case "London":
        return LondonImg;
      case "Manchester":
        return ManchesterImg;
      case "Middlesbrough":
        return MiddlesbroughImg;
      case "Milton Keynes":
        return MiltonKeynesImg;
      case "Newcastle":
        return NewcastleImg;
      case "Northampton":
        return NorthamptonImg;
      case "Norwich":
        return NorwichImg;
      case "Oxford":
        return OxfordImg;
      case "Peterborough":
        return PeterboroughImg;
      case "Plymouth":
        return PlymouthImg;
      case "Reading":
        return ReadingImg;
      case "Sheffield":
        return SheffieldImg;
      case "Shrewsbury":
        return ShrewsburyImg;
      case "Southampton":
        return SouthamptonImg;
      case "Stoke-on-Trent":
        return StokeOnTrentImg;
      case "Sunderland":
        return SunderlandImg;
      case "Swansea":
        return SwanseaImg;
      case "Swindon":
        return SwindonImg;
      case "TunbridgeWells":
        return TunbridgeWellsImg;
      case "WestWales":
        return WestWalesImg;
      case "Weston-super-Mare":
        return WestonSuperMareImg;
      case "Wrexham":
        return WrexhamImg;
      case "York":
        return YorkImg;
      case "Aberdeen":
        return AberdeenImg;
      case "Burnley":
        return BurnleyImg;
      case "Carlisle":
        return CarlisleImg;
      case "Cornwall":
        return CornwallImg;
      case "Derby":
        return DerbyImg;
      case "Devon":
        return DevonImg;
      case "Glasgow":
        return GlasgowImg;
      case "IsleOfMan":
        return IsleOfManImg;
      case "Lincoln":
        return LincolnImg;
      case "MidWales":
        return MidWalesImg;
      case "Nottingham":
        return NottinghamImg;
      case "Portsmouth":
        return PortsmouthImg;
      case "Stafford":
        return StaffordImg;
      case "Worcester":
        return WorcesterImg;
      case "Sold Out":
        return SoldoutImg;
      default:
        return teamthree;
    }
  };

  const fetchData = async (value) => {
    const q = query(
      collection(db, "venues"),
      where("showName", "==", value),
      orderBy("showDate", "asc")
    );

    try {
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return [];
    }
  };

  useEffect(() => {
    fetchData(value).then((data) => {
      setShowData(data);
      setLoading(false); // Mark the loading as complete
    });
  }, [value]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <section className="team-section mb-0">
        <div className="auto-container">
          <section className="menu-section">
            <div className="auto-container">
              <div className="title-box centered">
                <div className="subtitle">
                  <span>join us at the party to end all parties...</span>
                </div>

                <div className="pattern-image">
                  <img
                    src={
                      require("../../../assets/images/icons/separator.svg")
                        .default
                    }
                    alt="mySvgImage"
                  />
                </div>

                <h2>Information</h2>
              </div>

              <div className="tabs-box menu-tabs">
                <div className="buttons">
                  <ul className="tab-buttons clearfix">
                    <li
                      className={`tab-btn ${tabMenu.a && "active-btn"}`}
                      onClick={() => tabActive({ a: true })}
                      data-tab="#tab-1"
                    >
                      <span>THE EVENT</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.b && "active-btn"}`}
                      onClick={() => tabActive({ b: true })}
                      data-tab="#tab-2"
                    >
                      <span>DRESS CODE</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.c && "active-btn"}`}
                      onClick={() => tabActive({ c: true })}
                      data-tab="#tab-3"
                    >
                      <span>WARNINGS</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.d && "active-btn"}`}
                      onClick={() => tabActive({ d: true })}
                      data-tab="#tab-4"
                    >
                      <span>MENU</span>
                    </li>
                  </ul>
                </div>
                <div className="tabs-content">
                  <div
                    className={`tab ${tabMenu.a && "active-tab"}`}
                    id="tab-1"
                  >
                    <div className="row clearfix">
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div>
                            We welcome you to the Overlook Hotel where you will
                            enter a 1920's Cocktail Soiree to end all soirees.
                            ‘The Overlook Hotel’ is an immersive dining and
                            theatre experience, transporting the audience back
                            to a 1920’s style ball with a dark twist, and
                            everyone who attends should dress to kill. The
                            performance is played around your table, which sits
                            you right in the centre of the thrills. With a cast
                            of 3, hosted by Lloyd the Bartender, watch to see
                            how the new caretaker and his wife cope living for
                            months in the isolated hotel on the snowy mountains.
                            Nothing to help them and no one to hear their
                            screams. They are alone...or are they?
                          </div>
                        </div>
                      </div>
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner mr-5">
                          <img
                            src={manSmoking}
                            className="rounded mx-5"
                            style={{ height: "300px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab ${tabMenu.b && "active-tab"}`}
                    id="tab-2"
                  >
                    <div className="row clearfix">
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <h4>1920s glamour</h4>

                          <p className="my-3">
                            We recommend all participants embrace the elegance
                            of 1920’s fashion. Smart-casual clothing is also
                            suitable
                          </p>

                          <p className="my-3">
                            Please note that the dress code is a recommendation
                            not a requirement
                          </p>
                        </div>
                      </div>
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <img
                            src={menFashion}
                            style={{ height: "300px" }}
                            className="rounded mr-3"
                          />
                          <img
                            src={womenFashion}
                            style={{ height: "300px", width: "250px" }}
                            className="rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab ${tabMenu.c && "active-tab"}`}
                    id="tab-3"
                  >
                    <div className="row clearfix">
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="mt-4">
                            **Please be aware of sensitive content relating to
                            domestic abuse.**
                          </div>
                          <div className="mt-4">
                            **Please be aware of strong language used in this
                            show**
                          </div>
                          <div className="my-3">
                            **Strobe lighting is used is this event.**
                          </div>
                          <div className="mb-5">
                            **May not be suitable for people under the age of
                            15. Anyone under the age of 18 must be accompanied
                            by an adult**
                          </div>
                        </div>
                      </div>
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <img
                            src={warning}
                            style={{ height: "250px", width: "250px" }}
                            className="ml-5"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab ${tabMenu.d && "active-tab"}`}
                    id="tab-4"
                  >
                    <div className="row clearfix">
                      <div className="container text-center mb-5">
                        <h4>
                          This is a sample menu and can vary depending on the
                          venue, please contact them directly.
                        </h4>
                      </div>
                      <div className="container text-center">
                        <h2>Starters</h2>
                      </div>
                      <div className="menu-col cols-lg-6 col-md-12 col-sm-12">
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6 " id="boxHoverMenu">
                              <h4 class="card-title my-2  d-flex justify-content-center">
                                Vegetarian
                              </h4>
                              <div className="dish-block">
                                <div className="inner-box" style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-4">
                                        Butter bean creole
                                        <span className="s-info">VEGAN</span>
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    a mildly spiced dish served with a crusty
                                    roll.
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6  " id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-4">Prawn creole</h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    a mildly spiced dish served with a crusty
                                    roll.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2 className="d-flex justify-content-center mt-4">
                          Mains
                        </h2>
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6 " id="boxHoverMenu">
                              <h4 class="card-title my-2 d-flex justify-content-center">
                                Vegetarian
                              </h4>
                              <div className="dish-block">
                                <div className="title clearfix d-flex justify-content-center">
                                  <div className="ttl clearfix">
                                    <h5 className="ml-3">
                                      Beetroot and Shallot Wellington{" "}
                                      <span className="s-info">VEGAN</span>
                                    </h5>
                                  </div>
                                  <div className="price"></div>
                                </div>
                                <div className="text desc text-center">
                                  served with mashed potatoes, broad bean
                                  casserole and gravy (v, vg)
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 " id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-3">
                                        Thanks Giving Dinner{" "}
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    Turkey, Cranberry and mashed potatoes served
                                    with a broad bean casserole and gravy au
                                    jus.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2 className="d-flex justify-content-center mt-4">
                          Dessert
                        </h2>
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6 " id="boxHoverMenu">
                              <h4 class="card-title my-2 d-flex justify-content-center">
                                Vegan
                              </h4>
                              <div className="dish-block">
                                <div className="title clearfix d-flex justify-content-center">
                                  <div className="ttl clearfix text-center">
                                    <h5 className="ml-3">
                                      'Ice cream' with Red Rum and passion fruit
                                      puree
                                      <span className="s-info text-center">
                                        VEGAN
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="price"></div>
                                </div>
                                <div className="text desc text-center">
                                  Garnished with a meringue snow
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 " id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Vegetarian
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-3">
                                        Ice cream with Red Rum and passion fruit
                                        puree
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    Garnished with a meringue snow
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="text-center mb-4">
            <span>
              Please note all our bookings are handled externally by our venue
              host
            </span>
          </div>

          <div className="row justify-content-center clearfix">
            {showData.map((item) => (
              <div className="team-block col-lg-4 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="0ms"
                >
                  <div className="image">
                    <img src={findImgPath(item.selectWhere)} alt="" />
                    <div className="overlay-box">CLICK BELOW TO BOOK</div>
                  </div>
                  <h3>
                    <Link to="#">{item.hotelName}</Link>
                  </h3>
                  <div className="designation">{item.showDate}</div>
                  <div className="text">{item.hotelLocation}</div>
                  <Link to={item.hotelLink}>
                    <button
                      type="submit"
                      className="theme-btn btn-style-one clearfix mt-2"
                    >
                      <span className="btn-wrap">
                        <span className="text-one">Book</span>
                        <span className="text-two">Book</span>
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            ))}

            <div className="team-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <div className="image">
                  <img src={teamthree} alt="" />
                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <ul className="social-box"></ul>
                    </div>
                  </div>
                </div>
                <h3>
                  <Link to="#">Coming soon...</Link>
                </h3>
                <div className="designation">2025</div>
                <div className="text">Coming soon...</div>
                <button
                  type="submit"
                  className="theme-btn btn-style-one clearfix mt-3"
                >
                  <span className="btn-wrap">
                    <span className="text-one">Book</span>
                    <span className="text-two">Book</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privateevent;
